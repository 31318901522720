@use '~@keyliving/component-lib/dist/scss/utils';
@use '../../scss/typography';

.reset {
    @include typography.text-link;
}

.error-msg {
    margin: 1rem 0;
    color: utils.color('error');
    text-align: left;
}
