@use '~@keyliving/component-lib/dist/scss/utils' as *;

.wrapper {
    position: fixed;
    z-index: 1000;
    display: grid;
    width: 100%;
    height: 100vh;
    justify-items: center;
    align-items: center;
    padding: 4rem;
    background-color: white;
}

.loading-indicator {
    color: color('royal', 500);
}
