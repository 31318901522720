@use '~@keyliving/component-lib/dist/scss/utils' as *;

.unsaved {
    display: flex;
    font-weight: 600;
    color: color('orange', 900);
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}
